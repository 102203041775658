import { createSelector } from 'reselect';
import { getSelectedBoardPriceSelector } from './getSelectedBoardPriceSelector';
import { getBoardQuantitySelector } from './getBoardQuantitySelector'

export const getEstimationCost = createSelector(
  getSelectedBoardPriceSelector,
  getBoardQuantitySelector,
  (boardPrice, quantity) => boardPrice * quantity,
);

export default getEstimationCost;
