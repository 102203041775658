import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import * as S from './Styled.BuildCheckboxProduct';
import { getNumberOfProductUnitsRequired } from '../../services/productUnitsRequiredApi';

import {
  UPDATE_SUB_APP_HARDWARE_PRICING,
  UPDATE_SUB_APP_HARDWARE_QUANTITY,
  SUB_APP_HARDWARE_SELECTED,
} from '../../../../actions';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const checkIfSelected = (selectedProducts, productId) => {
  const found = selectedProducts.some((id) => id === productId);
  return found || false;
};

const enableOrDisableCheckbox = (productId, disableCheckbox, isSelected) => {
  // eslint-disable-next-line prefer-const
  let elm = document.getElementById(`checkbox-${productId}`);
  if ((disableCheckbox && isSelected)) {
    elm.click();
  }
}

const BuildCheckboxProduct = ({
   mainImage, name, shortLabel,
  longLabel, selectedProducts, callback,
  preselectCallback, context, coverageType, areaCoverage,
  callbackHandler, hardwarePrice, availableBoardsInStock,
  hardwareItem, boards, deckArea,calculatePrice
}) => {

  const { decksApiUrl } = context;
  const [hardwareQuantity, setHardwareQuantity] = useState(1);
  const [hardwarePricing, setHardwarePricing] = useState(hardwarePrice.price);
  const calculateHardwarePrice = (price, qty) => (price * qty);


  const disableCheckbox = () => {
    let disabled;
    //disbaling checkbos if board fulfillment is BOPIS
    if (availableBoardsInStock > 0) {
      disabled = true;
    }
    else if(!hardwareItem.omsId ||  hardwareItem.omsId === "") {
      disabled = true;
    }
    else if (!hardwareItem.isBOPIS) {
      disabled = false;
    }
    else {
      disabled = hardwareItem.quantityAvailable < hardwareItem.quantityOrdered;
    }
    return disabled;
  }



  useEffect(() => {
    getNumberOfProductUnitsRequired(decksApiUrl, boards, deckArea, coverageType, areaCoverage).then((response) => {
      
      setHardwareQuantity(response.data);
      callbackHandler(UPDATE_SUB_APP_HARDWARE_QUANTITY, { subappId: 'hardware',  productId: hardwareItem.productId, quantity: response.data });
      callbackHandler(UPDATE_SUB_APP_HARDWARE_PRICING, { subappId: 'hardware',  productId: hardwareItem.productId, pricing: hardwarePrice });
    
      // console.log('calling calling',calculatePrice);
      calculatePrice();
    });
      preselectCallback(hardwareItem.productId);
      return () => {
        preselectCallback(hardwareItem.productId);
      }
  }, []);

  useEffect(() => {
    const isSelected = checkIfSelected(selectedProducts, hardwareItem.productId);
    enableOrDisableCheckbox(hardwareItem.productId, disableCheckbox(), isSelected);
  }, [hardwareItem.quantityOrdered])


  const boardPreviousState = usePrevious(boards);

  useEffect(() => {
    if (boardPreviousState && !isEqual(boardPreviousState, boards)) {
      getNumberOfProductUnitsRequired(decksApiUrl, boards, deckArea, coverageType, areaCoverage).then((response) => {
        setHardwareQuantity(response.data);
        callbackHandler(UPDATE_SUB_APP_HARDWARE_QUANTITY, { subappId: 'hardware', productId: hardwareItem.productId, quantity: response.data });

        calculatePrice();
      });
    }
  }, [boards]);

  const hardwarePricePreviousState = usePrevious(hardwarePrice);

  useEffect(() => {
    if (hardwarePricePreviousState && !isEqual(hardwarePricePreviousState, hardwarePrice)) {
      setHardwarePricing(hardwarePrice.price);
      callbackHandler(UPDATE_SUB_APP_HARDWARE_PRICING, { subappId: 'hardware', productId: hardwareItem.productId, pricing: hardwarePrice });
      
    }
    setHardwarePricing(hardwarePrice.price);
   
  }, [hardwarePrice]);


  const currentlySelected = disableCheckbox() ? false : checkIfSelected(selectedProducts, hardwareItem.productId);

  const calculatedHardwarePrice = calculateHardwarePrice(hardwarePricing, hardwareQuantity);

  const selectedProductsPreviousState = usePrevious(selectedProducts);

  const hardwareItemPreviousState = usePrevious(hardwareItem);


  useEffect(() => {
    if ((selectedProductsPreviousState && !isEqual(selectedProductsPreviousState, selectedProducts)) ||
    (hardwareItemPreviousState && !isEqual(hardwareItemPreviousState, hardwareItem))
    ){
      let selected = false;
      if (currentlySelected !== false) {
        selected = true;
      };
      callbackHandler(SUB_APP_HARDWARE_SELECTED, { subappId: 'hardware', productId: hardwareItem.productId, selected });
      
    }
    
  }, [selectedProducts, hardwareItem.omsId]);


  const availableBoardsInStockPreviousState = usePrevious(availableBoardsInStock);

  useEffect(() => {
    if (!isEqual(availableBoardsInStockPreviousState, availableBoardsInStock)) {
      enableOrDisableCheckbox(hardwareItem.productId, disableCheckbox());
    }
  }, [availableBoardsInStock]);

  return (
    <>
      <S.Checkbox
        type="checkbox"
        id={`checkbox-${hardwareItem.productId}`}
        name={`checkbox-${hardwareItem.productId}`}
        value={hardwareItem.productId}
        onChange={callback}
        checked={currentlySelected}
        inactive={disableCheckbox()}
      />
      <S.Label htmlFor={`checkbox-${hardwareItem.productId}`} inactive={disableCheckbox()}>
        <S.ImageContainer>
          <img src={mainImage} alt={name} />
        </S.ImageContainer>
        <S.Content>
          <S.ShortLabel>{`${shortLabel} (Quantity: ${hardwareQuantity})`}
            {
              calculatedHardwarePrice > 0 &&
              (
                <S.Price>
                  {`, +$${calculatedHardwarePrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                </S.Price>
            )
            }
          </S.ShortLabel>
          <S.Name>{name}</S.Name>
          <S.LongLabel>
            {longLabel}
          </S.LongLabel>
        </S.Content>
      </S.Label>
    </>
  );
};

BuildCheckboxProduct.defaultProps = {
  callback: noop,
  preselectCallback: noop,
  selectedProducts: [],
  hardwarePrice: { price: 0.00 },
  availableBoardsInStock: undefined,
};

BuildCheckboxProduct.propTypes = {
  mainImage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortLabel: PropTypes.string.isRequired,
  longLabel: PropTypes.string.isRequired,
  callback: PropTypes.func,
  preselectCallback: PropTypes.func,
  selectedProducts: PropTypes.array,
  context: PropTypes.object.isRequired,
  coverageType: PropTypes.string.isRequired,
  areaCoverage: PropTypes.string.isRequired,
  callbackHandler: PropTypes.func.isRequired,
  hardwarePrice: PropTypes.object,
  availableBoardsInStock: PropTypes.number,
  hardwareItem: PropTypes.object.isRequired,
  boards: PropTypes.array.isRequired,
  deckArea: PropTypes.object.isRequired,
  calculatePrice: PropTypes.func.isRequired
};

export default BuildCheckboxProduct;
