import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import { INIT_DECKING, setConfigSettings, getHardware } from '../../actions';
import { safeSaga } from '../utils';
import { getproductInfoApi } from '../../services';
import injectedConfiguratorSettings from '../../../../config';

function* initializeDecking() {
  const configSettings = { ...injectedConfiguratorSettings };  
  const { status, data } = yield call(getproductInfoApi, configSettings.eleanorBaseUrl, configSettings.partnerProductId, configSettings.siteId);
  LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_decking", true);

  if (status === 200) {
    configSettings.productId = data.productId;
    configSettings.productName = data.name;
    configSettings.externalName = data.externalName;
    configSettings.externalId = data.externalId;
    configSettings.attributes = data.attributes;
    configSettings.assemblyId = data.attributes.assemblyId;
    configSettings.scene7Folder = data.attributes.scene7Folder;
    configSettings.productionTime = data.productionTime;
    
    yield put(setConfigSettings(configSettings));

  }
  
  yield put(getHardware(
    configSettings.decksApiUrl,
    configSettings.assemblyId
  ));

}


export function* initSaga() {

  yield takeLatest(
    [INIT_DECKING],
    safeSaga(initializeDecking),
  );
}

export default initSaga;
