import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import noop from 'lodash/noop';

import { bindActionCreators } from 'redux';
import * as S from './Styled.HardwareProduct';
import BuildCheckboxProduct from '../BuildCheckboxProduct';
import BuildDropdownProduct from '../BuildDropdownProduct';
import selectedColorProjector from '../../projectors/selectedColorProjector';
import { changeProductSelection } from '../../actions/index';
import { recalculatePrice } from '../../../../actions/index';
import updateSelectedHardware from '../../actions/updateSelectedHardwareThunk';
import productsWithColorOptionProjector from '../../projectors/productsWithColorOption';
import choicesForOptionProjector from '../../projectors/choicesForOptionProjector';
import showNoMatchMessageProjector from '../../projectors/showNoMatchMessageProjector';
import productsSelector from '../../selectors/productsSelector';
import selectedProductsSelector from '../../selectors/selectedProductsSelector';
import theContext from '../../context';
import WarningIcon from '../Icons/WarningIcon';
import selectedHardwarePriceSelector from '../../selectors/selectedHardwarePriceSelector';
import { hardwareAssemblySelectorById } from '../../../../selectors/graphs/hardwareAssemblySelectors';
import { ScrewColor } from '../../types/ScrewColor';

import {
  SUB_APP_HARDWARE_SELECTED,
} from '../../../../actions';

const HardwareProduct = (props) => {
  const {
    product,
    colorOptionsAvailable,
    colorChoices,
    selectProduct,
    selectedColor,
    colorOptionId,
    changeColorSelection,
    showNoMatchMessage,
    selectedProducts,
    PrimaryColorSelectedColor,
    context,
    coverageType,
    areaCoverage,
    callbackHandler,
    hardwarePrice,
    availableStock,
    hardwareItem,
    disabled,
    boards,
    deckArea,
    calculatePrice,
  } = props;
  
  const [colorHasChanged, setColorHasChanged] = useState(false);
  const [savedSelectedColor, setSelectedColor] = useState(selectedColor);
  const [savedPrimarySelectedColor, setPrimarySelectedColor] = useState('');

  //temporery hack to determine the inventory mode of boards. needs refactoring
  const isDeckBoardBOPIS = availableStock>0;
  const primaryColorChanged = savedPrimarySelectedColor !== PrimaryColorSelectedColor;
  const changeToColor = savedSelectedColor.internalName && !primaryColorChanged ? savedSelectedColor: selectedColor;

  useEffect( () => {
    if (primaryColorChanged) {
      setPrimarySelectedColor(PrimaryColorSelectedColor);    
      if (selectedColor && selectedColor.internalName) {
        setSelectedColor(selectedColor);
      }
    }
  });

  useEffect(
    () => {
      setColorHasChanged(false);
    },
    [PrimaryColorSelectedColor],
  );

  const colorChange = (newColor) => {
    setSelectedColor(newColor);
    setPrimarySelectedColor(PrimaryColorSelectedColor);
    const { id, shortLabel } = newColor;
    if (!colorHasChanged) setColorHasChanged(true);
    changeColorSelection(product.productId, colorOptionId, id, shortLabel);
  };

  const preselectProductCallback = (productId) => {
    selectProduct(productId);
  };

  let availabilityError;
  let availabilityErrorDetails;
  if (showNoMatchMessage && !colorHasChanged) {
    availabilityError = "An exact matching screw color is unavailable.";
    availabilityErrorDetails = "Below is a preselected alternative.";
  }
  return (
    <S.HardwareProduct className="hardware-assembly-item">
      {
        availabilityError && !disabled ? (
          <S.NoMatchErrorContainer>
            <WarningIcon />
            <S.NoMatchErrorContentContainer>
              <S.NoMatchErrorTitle className="color-availability-error">
                {availabilityError}
              </S.NoMatchErrorTitle>
              {availabilityErrorDetails}
            </S.NoMatchErrorContentContainer>
          </S.NoMatchErrorContainer>
        ) : null
      }
      <BuildCheckboxProduct
        {...product}
        selectedProducts={selectedProducts}
        preselectCallback={preselectProductCallback}
        context={context}
        coverageType={coverageType}
        areaCoverage={areaCoverage}
        callback={(e) => {
          callbackHandler(SUB_APP_HARDWARE_SELECTED, { subappId: 'hardware', productId: e.target.value, selected: e.target.checked });
          calculatePrice();
          return selectProduct(e.target.value)
        }}
        callbackHandler={callbackHandler}
        hardwarePrice={hardwarePrice}
        availableBoardsInStock={availableStock}
        hardwareItem={hardwareItem}
        boards={boards}
        deckArea={deckArea}
        calculatePrice={calculatePrice}
      />
      {colorOptionsAvailable && (
        <BuildDropdownProduct
          title="Screw Color"
          infoDeck="tooltip/screw-color"
          colorOptionsList={colorChoices}
          callback={colorChange}
          selectedColor={changeToColor}
          showNoMatchMessage={showNoMatchMessage}
          active={!isDeckBoardBOPIS}
        />
      )}
    </S.HardwareProduct>
  );
};


HardwareProduct.propTypes = {
  product: PropTypes.object.isRequired,
  colorOptionsAvailable: PropTypes.bool,
  colorChoices: PropTypes.array,
  selectedProducts: PropTypes.array,
  selectProduct: PropTypes.func.isRequired,
  selectedColor: ScrewColor,
  PrimaryColorSelectedColor: PropTypes.string,
  colorOptionId: PropTypes.number,
  changeColorSelection: PropTypes.func,
  showNoMatchMessage: PropTypes.bool.isRequired,
  context: PropTypes.object.isRequired,
  coverageType: PropTypes.string.isRequired,
  areaCoverage: PropTypes.string.isRequired,
  callbackHandler: PropTypes.func.isRequired,
  hardwarePrice: PropTypes.object.isRequired,
  availableStock: PropTypes.number.isRequired,
  hardwareItem: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  boards: PropTypes.array.isRequired,
  deckArea: PropTypes.object.isRequired,
};

HardwareProduct.defaultProps = {
  colorOptionsAvailable: false,
  colorChoices: [],
  selectedColor: undefined,
  colorOptionId: undefined,
  changeColorSelection: noop,
  selectedProducts: [],
  PrimaryColorSelectedColor: {},
  disabled: false,
};

const mapStateToProps = (state, ownProps) => {
  const { calculatorDetails, context } = ownProps;
  const { availableStock } = context;
  const { areaCoverage, coverageType } = calculatorDetails;
  const productId = calculatorDetails.productId.toString();
  const theProduct = productsSelector(state)[productId];
  const productsWithColorOption = productsWithColorOptionProjector(state);
  const theProductsColorOptionData = productsWithColorOption.find((d) => d.productId === productId);
  const { selectedColor: PrimaryColorSelectedColor } = theContext;

  const hardwareItem = hardwareAssemblySelectorById(state)(productId);

  let colorChoices;
  let selectedColor;
  let colorOptionId;
  let
    colorOptionsAvailable;
  if (theProductsColorOptionData) {
    colorOptionsAvailable = true;
    colorChoices = choicesForOptionProjector(theProductsColorOptionData.colorOptionId)(state);
    selectedColor = selectedColorProjector(productId)(state);
    colorOptionId = theProductsColorOptionData.colorOptionId;
  } else {
    colorOptionsAvailable = false;
    colorChoices = [];
    selectedColor = {};
    colorOptionId = undefined;
  }

  const showNoMatchMessage = showNoMatchMessageProjector(PrimaryColorSelectedColor, selectedColor);
  const selectedProducts = selectedProductsSelector(state);
  const hardwarePrice = selectedHardwarePriceSelector(productId)(state);


  return {
    product: theProduct,
    colorOptionsAvailable,
    colorOptionId,
    colorChoices,
    selectedColor,
    showNoMatchMessage,
    selectedProducts,
    PrimaryColorSelectedColor,
    context,
    coverageType,
    areaCoverage,
    hardwarePrice,
    availableStock,
    hardwareItem,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ selectProduct: updateSelectedHardware, changeColorSelection: changeProductSelection,calculatePrice:recalculatePrice }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HardwareProduct);
