export const IsValidDimension = (dimension) => {
  if (!dimension.toLowerCase) return false;
  const dim = dimension.toLowerCase();
  return dim.length > 2 && dim.indexOf('x') < dim.lastIndexOf('x');
};

export default function getBoardWidth(dimension) {
  if (!dimension) {
    return;
  }

  const boardDimension = dimension.toLowerCase();

  if (!IsValidDimension(boardDimension)) {
    throw new Error(`${dimension} is an invalid board dimension.`);
  }

  const parts = boardDimension && boardDimension.split('x');
  const width = parseInt(parts[1], 10);
  const length = parseInt(parts[2], 10);

  if (Number.isNaN(width)) {
    throw new Error(`${dimension} has invalid board dimension width.`);
  }
  if (Number.isNaN(length)) {
    throw new Error(`${dimension} has invalid board dimension length.`);
  }

  return { width, length };
}
