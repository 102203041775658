import { getSelectedPrimaryColor } from '../utils/getSelectedChoices';
import { getSelectedChoicesSelector, getChoicesSelector } from '../selectors';


const getSelectedColorProjector = (state) => {
  const currentSelectedChoiceId = getSelectedPrimaryColor(getSelectedChoicesSelector(state))[0]?.choiceId;  
  const choices = getChoicesSelector(state);
  return choices[currentSelectedChoiceId]?.tags?.find((tag) => tag.includes('colormatch'));
};

export default getSelectedColorProjector;
