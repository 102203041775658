import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import hardwareAssemblyProjector from '../../projectors/hardwareAssemblyProjector';
 import { HardwareAssembly } from '../../_SubApps/HardwareAssembly/index';
import {
    UPDATE_SUB_APP_HARDWARE_PRICING,
    updateSubAppHardwarePricing,
    UPDATE_SUB_APP_HARDWARE_QUANTITY,
    updateSubAppHardwareQuantity,
    SUB_APP_HARDWARE_SELECTED,
    subAppHardwareSelected,
    SUB_APP_SELECTED_EDGE_CHANGED,
    subAppSelectedEdgeChanged,
} from '../../actions';

export const HardwareAssemblyWrapper = (props) => {
  const {
      onSubAppPricingUpdate,
      onSubAppHardwareQuantity,
      onSubAppHardwareSelected,
      onSubAppSelectedEdgeChanged,
      assemblyId,
    } = props;

  const callbackHandler = (actionType, payload) => {
    switch(actionType) {
       case UPDATE_SUB_APP_HARDWARE_PRICING:
            onSubAppPricingUpdate(payload.subappId, payload.productId, payload.pricing);
            break;

        case UPDATE_SUB_APP_HARDWARE_QUANTITY:
            onSubAppHardwareQuantity(payload.subappId, payload.productId, payload.quantity);
            break;

        case SUB_APP_HARDWARE_SELECTED:
            onSubAppHardwareSelected(payload.subappId, payload.productId, payload.selected);
            break;

        case SUB_APP_SELECTED_EDGE_CHANGED:
            onSubAppSelectedEdgeChanged();
            break;

        default:
            break;
    }
  };

  if (!(+assemblyId)) return null;
  return (
  
    <HardwareAssembly
      {...props}
      selectedEdge={props.selectedEdge}
      callbackHandler={callbackHandler}
    />
  );
};

HardwareAssemblyWrapper.defaultProps = {
  assemblyId: 0,
};

HardwareAssemblyWrapper.propTypes = {
  assemblyId: PropTypes.number,
  selectedEdge: PropTypes.string.isRequired,
  storeId: PropTypes.number.isRequired,
  decksApiUrl: PropTypes.string.isRequired,
  boards: PropTypes.array.isRequired,
  onSubAppPricingUpdate: PropTypes.func.isRequired,
  onSubAppHardwareQuantity: PropTypes.func.isRequired,
  onSubAppHardwareSelected: PropTypes.func.isRequired,
  onSubAppSelectedEdgeChanged: PropTypes.func.isRequired,
  deckArea: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => hardwareAssemblyProjector(state);

const mapDispatchToProps = (dispatch) => ({
    onSubAppPricingUpdate: (subappId, productId, pricing) => dispatch(updateSubAppHardwarePricing(subappId, productId, pricing)),
    onSubAppHardwareQuantity: (subappId, productId, quantity) => dispatch(updateSubAppHardwareQuantity(subappId, productId, quantity)),
    onSubAppHardwareSelected: (subappId, productId, selected) => dispatch(subAppHardwareSelected(subappId, productId, selected)),
    onSubAppSelectedEdgeChanged: () => dispatch(subAppSelectedEdgeChanged()),
  });

  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HardwareAssemblyWrapper);
