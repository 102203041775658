import Api from './axios';
import { assertHasShape } from '../apps/debug/assert';
import config from '../../../config';

export const getTooltip = (term) => {
  const { eleanorBaseUrl } = config;
  const api = Api();

  return api.get(`${eleanorBaseUrl}/DictionaryManagement/Definition/tooltip?term=${term}`)
    .then(response => {
      assertHasShape('getTooltip response', response.data, {
        term: 'string',
        mediaUrl: 'string',
        videoTag: 'nullableString',
        definition: 'string'
      });
      return response;
    });
  ;
}

export default getTooltip;
