import Api from '../../../services/axios';
import { assertHasShape } from '../../../apps/debug/assert';

export const getNumberOfProductUnitsRequired = (decksApiBaseUrl, boards, deckArea, coverageType, coveragePerUnit) => {
  const url = `${decksApiBaseUrl}/Calculation/GetNumberOfProductUnitsRequired`;
  const api = Api();  
  return api.post(url, { boards, deckArea, coverageType, coveragePerUnit })
  .then(response => {
    assertHasShape('getNumberOfProductUnitsRequired response', response, {
      data: 'number'
    });
    return response;
  });
};

export default getNumberOfProductUnitsRequired;
