import isEqual from 'lodash/isEqual';


const noPropsChanged = (prevProps, currentProps) => {
  const {
    assemblyId, selectedEdge, storeId, selectedColor, boards,
  } = prevProps;

  return assemblyId === currentProps.assemblyId
    && storeId === currentProps.storeId
    && selectedEdge === currentProps.selectedEdge
    && selectedColor === currentProps.selectedColor
    && isEqual(boards, currentProps.boards);
};

const callAssemblyLifeCycle = (prevProps, currentProps, hardwareLifeCycle, colorMatch) => {
  const { selectedEdge, selectedColor } = prevProps;

  if (noPropsChanged(prevProps, currentProps)) return;

  const onlyTheEdgeHasChanged = selectedEdge !== currentProps.selectedEdge;
  if (onlyTheEdgeHasChanged) return;

  const colorHasChanged = selectedColor !== currentProps.selectedColor;
  if (colorHasChanged) {
    colorMatch(currentProps.selectedColor);
    return;
  }

  // assembly id or storeId has changed
  if(prevProps.assemblyId !== currentProps.assemblyId || prevProps.storeId !== currentProps.storeId)
  hardwareLifeCycle(currentProps.decksApiUrl, currentProps.assemblyId, currentProps.storeId, currentProps.selectedColor);
};


export default callAssemblyLifeCycle;
