export default (state, {
  productId, optionId, choiceId, choiceValue,
}) => {
  const selections = { ...state.selections };
  if (selections[productId]) {
    selections[productId][optionId] = { choiceId, choiceValue, optionId };
  } else {
    selections[productId] = { [optionId]: { choiceId, choiceValue, optionId  } };
  }

  return { ...state, selections };
};
