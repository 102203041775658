import { createSelector } from 'reselect';
import * as _ from 'lodash';

import { getOptionsSelector } from './getOptionsSelector';

import getSelectedChoicesSelector from './getSelectedChoicesSelector';
import { productSkusSelector } from './catalog';
import marketAvailabilitySelector from './marketAvailabilitySelector';

export const isConfigurationCartable = createSelector(
  marketAvailabilitySelector,
  getSelectedChoicesSelector,
  productSkusSelector,
  getOptionsSelector,
  (isInMarket, selectedChoices, productskus, options) => {
    const requiredOptions = _.filter(options, 'isRequired');
    const requiredOptionIds = _.map(requiredOptions, 'id');
    const selectedOptionIds = _.map(selectedChoices, 'optionId');
    const requiredOptionsSelected = _.intersection(requiredOptionIds, selectedOptionIds);
    const allRequiredOptionsSelected = requiredOptionsSelected.length === requiredOptions.length;
    return (isInMarket ? allRequiredOptionsSelected && productskus.length > 0 : true);
  },
);

export default isConfigurationCartable;
