import {
  put, takeLatest, select, fork, call,delay
} from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import { safeSaga } from '../utils';

import { SET_ESTIMATED_SQFT } from '../../apps/actions/actionTypes';

import { updateHardwareQuantity, updateHardwareCost } from '../../actions';

import {
  hardwareEstimatesSelector,
  hardwareIdsSelector,
  hardwareProductsSelector,
  hardwareConfigurationsSelector,
} from '../../selectors';
import computeHardwareCost from './computeHardwareCost';
import hiddenOptionIdsSelector from '../../selectors/hardware/hiddenOptionIdsSelector';

import { getSelectedStore } from '../../utils/getSelectedStoreInfo';

function* getAndUpdatedHardwareCost(hardwareId, quantity) {
  const { storeId } = getSelectedStore();
  const { vendorOptionId, productOptionId, partNumberOptionId } = yield select(hiddenOptionIdsSelector);

  const estimates = yield select(hardwareEstimatesSelector);
  const configurations = yield select(hardwareConfigurationsSelector);

  const { unitPrice } = estimates[hardwareId];

  let vendorNumber;
  const foundHiddenOptionIds = vendorOptionId && productOptionId && partNumberOptionId;
  if (foundHiddenOptionIds) {
    vendorNumber = configurations[hardwareId][vendorOptionId]
      ? configurations[hardwareId][vendorOptionId].choiceValue
      : 0;
  }

  const alreadyFetchedHardwarePrice = unitPrice && vendorNumber;
  if (alreadyFetchedHardwarePrice) {
    const fullPrice = quantity * unitPrice;
    const productNumber = configurations[hardwareId][productOptionId]
      ? configurations[hardwareId][productOptionId].choiceValue
      : 0;
    const vendorPartNumber = configurations[hardwareId][partNumberOptionId]
      ? configurations[hardwareId][partNumberOptionId].choiceValue
      : 0;
    yield put(updateHardwareCost(
      hardwareId,
      fullPrice,
      vendorNumber,
      unitPrice,
      productNumber,
      storeId,
      vendorPartNumber,
    ));
  } else {
    yield computeHardwareCost(storeId, hardwareId, configurations[hardwareId], {
      quantity,
    });
  }
}

function* estimateForHardware(hardwareId, sqft) {
  const products = yield select(hardwareProductsSelector);
  const coverage = products[hardwareId].attributes.areaCoverage;
  if (coverage) {
    const quantity = Math.ceil(sqft / coverage);
    yield put(updateHardwareQuantity(hardwareId, quantity));

    yield call(getAndUpdatedHardwareCost, hardwareId, quantity);
  } else {
    console.log(`Bad Coverage for : ${hardwareId}. Value: ${coverage}`);
  }
}

function* estimateAllHardware(action) {
  let isStoreAvailable = false;
  while (isStoreAvailable === false) {
    const { storeId } = getSelectedStore();
    if (storeId) {
      isStoreAvailable = true;
    } else {
      yield call(delay, 1000);
    }
  }
  const { sqft } = action.payload;
  const hardwareIds = yield select(hardwareIdsSelector);
  yield hardwareIds.map((id) => fork(estimateForHardware, id, sqft));
}

export function* hardwareEstimationSaga() {
  // console.log('Hardware Estimation saga started');
  yield takeLatest(SET_ESTIMATED_SQFT, safeSaga(estimateAllHardware));
}

export default hardwareEstimationSaga;
