import { createReducer } from '../utils';
import { SET_CONFIG_SETTINGS } from '../actions';

const onSetConfigSettings = (state, { settings }) => ({ ...state, settings});

export const config = createReducer(
  {},
  {
    [SET_CONFIG_SETTINGS]: onSetConfigSettings,
  },
);

export default config;
