import { call, put, takeLatest } from 'redux-saga/effects';
import { getVisualizerImages } from '../../services';

import {
  GET_MEDIA_GALLERY_IMAGES,
  mediaGalleryImagesReady,
  setMediaGalleryImages,
  setMediaGalleryImagesFailed
} from '../../actions';

import { safeSaga } from '../utils';

const getImagesFromApi = (data) => {
  const imageList = [];

  data.imageUrlList.forEach((image, index) => {
    imageList.push({
      key: index + 1,
      thumbnail: `${image}&hei=117`,
      primaryLink: `${image}&hei=1000`,
      mediaType: 'IMAGE',
    });
  });

  if (data.videoUrlList) {
    data.videoUrlList.forEach((video, index) => {
      imageList.push({
        key: index + data.imageUrlList.length + 1,
        thumbnail: video.thumbnail,
        primaryLink: video.videoUrl,
        mediaType: 'VIDEO',
      });
    });
  }

  return imageList;
};



function* visualizerImages(action) {
  if (action.payload.ProductId && action.payload.Scene7Folder) {
    const { data } = yield call(
      getVisualizerImages,
      action.decksApiUrl,
      action.payload,
    );
    if (Array.isArray(data.imageUrlList) && Array.isArray(data.videoUrlList)) {
      const images = getImagesFromApi(data);
      yield put(setMediaGalleryImages(images));
      yield put (mediaGalleryImagesReady());
    }
    else {
      yield put(setMediaGalleryImagesFailed());
    }

  }
}

export function* visualizerImageSaga() {
  yield takeLatest(GET_MEDIA_GALLERY_IMAGES, safeSaga(visualizerImages));
}

export default visualizerImageSaga;
