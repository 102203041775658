import productSkusSelector from '../../selectors/catalog/productSkusSelector';
import { getMediaGalleryImages } from '../../actions';

const getDeckVisualizerImageUrl = () => (dispatch, getState) => {
  const state = getState();
  const { configurator } = state;
  
  const productSkus = productSkusSelector(getState());

  const payload = {
    ProductId: configurator.productInfo.productId,
    Sku: productSkus[0],
    Scene7Folder: state?.config?.settings?.scene7Folder,
  };

  const { decksApiUrl } = state?.config?.settings;  

  dispatch(getMediaGalleryImages(decksApiUrl, payload));
};

export default getDeckVisualizerImageUrl;
