/* eslint no-else-return: 0 */
/* globals injectedConfiguratorSettings */
import axios from 'axios';
import constant from 'lodash/constant';
import map from 'lodash/map';
import noop from 'lodash/noop';

import templates from '../apps/templates';
import { getSelectedStore } from './getSelectedStoreInfo';
import failedToATC from './failedToCartAlert';
import store from '../store/globalStore';
import { disableSpinnerATC } from '../actions';
import { getPreconfiguration } from './pathUtils';
import { displayPriceSelector } from '../selectors/catalog/index';
import { getSelectableChoices } from './getSelectedChoices';

export default ({ onLoadSuccess,config,updatePricing }) => {
  // const config = injectedConfiguratorSettings;
  // console.log('injcted configs=>',config);
  const configuratorSettings = {
    configurationId: config.configurationId,
    mode: config.configuratorMode,
    templates,
    siteId: config.siteId,
    omsId: config.partnerProductId,
    productId: config.partnerProductId,
    productLineId: '4',
    productName: config.productName,
    productLine: 'decking',
    brand: config.brand,
    persistenceKey: config.productId,
    userId: null,
    deselectUnavailableChoices: true,
    hiddenOptions: [982],
    onRulesExecuted:updatePricing,
    mixPanelConfig: {
      timedEvent: 'PCP_TimeDevotion',
      isMixPanelActive: true,
      mixPanelCode: 'c2360a502ff32f3443acba1f20009e04',
    },
    preconfiguration: getPreconfiguration,
    zipcode: {},
    customValidators: {},
    staticImages: {},
    logEventsToConsole: config.logEnsightenEventsToConsole,
    executeRulesApi: `${
      config.eleanorBaseUrl
    }/Catalog/ProductConfiguration/executerulesviapost`,
    getOptionsApi: config.getOptionsApi,
    eleanorBaseUrl: config.eleanorBaseUrl,
    unavailableChoicesApi: (selectionsJson) => {
      const selectedChoices = JSON.parse(selectionsJson);
    
      const selectableChoices = getSelectableChoices(selectedChoices.Choices);
      const unavailableChoices = selectableChoices.map((c) => ({
        optionId: c.optionId,
        choiceIds: [],
      }));

      return Promise.resolve({
        status: 200,
        data: unavailableChoices,
      });
    },
    editConfigurationApi: (configuration) => axios({
      url: config.editConfigurationApi,
      data: {
        configurationId: config.configurationId,
        selections: map(configuration.selections, (choice) => ({
          optionId: choice.optionId,
          choiceId: choice.choiceId,
          choiceValue: choice.choiceValue,
          choicePrice: 0,
        })),
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    createConfigurationApi: (configuration) => axios({
      url: config.configApiUrl,
      data: {
        Siteid: 11,
        ProductId: config.productId,
        selections: map(configuration.selections, (choice) => ({
          choiceValue: choice.choiceId,
          optionId: choice.optionId,
          choiceName: choice.choiceValue,
          optionTags: [],
        })),
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    loadDictionaryDefinitionApi: `${config.eleanorBaseUrl}/DictionaryManagement/Definition/DictionaryDefinitionForTerm`,
    loadDictionaryTermsApi: `${config.eleanorBaseUrl}/DictionaryManagement/Definition/AllDictionaryEntries`,
    priceApi: () => {
      const price = displayPriceSelector(store.getState());
      // console.log('price updated => ', price);
      return Promise.resolve({
        status: 200,
        data: {
          storeId: getSelectedStore().storeId,
          asConfigured: price,
        },
      });
    },
    visualizationApi: () => Promise.resolve({ status: 200 }),
    onSaveSuccess: noop,
    onLoadSuccess,
    onSaveFailure(data, message) {
      // console.log(`save Configuration failure: ${message}`); // eslint-disable-line no-console
      failedToATC(message);
      store.dispatch(disableSpinnerATC(false));
    },
  };

  return configuratorSettings;
};
