/* eslint-disable no-param-reassign */
import { assert,  assertAssemblyShape } from '../../apps/debug/assert';
import { getSelectedEdgeSelector } from '../getSelectedEdgeSelector';
import selectedHardwarePriceSelector from '../../_SubApps/HardwareAssembly/selectors/selectedHardwarePriceSelector'; 


export const hardwareAssemblyIsBOPIS = (product) => {
  // TODO: This should be an assertion, but until the lifecycle is fixed, this will
  // get called on a bad state.  
  let fulfillmentOptions = {}
  if (product && product.pricing && product.pricing.fulfillmentOptions) {
    const isEmptyObject = x => typeof x === 'object' && Object.keys(x).length === 0;    
    fulfillmentOptions = product.pricing.fulfillmentOptions;
    assert({
      condition: fulfillmentOptions.buyOnlinePickupInStore === undefined || fulfillmentOptions.buyOnlineShipToStore === undefined,
      error: 'product.fulfillmentOptions has Both BOPIS and BOSS',
      data: { product }
    });
    assert({
      condition: isEmptyObject(fulfillmentOptions.buyOnlinePickupInStore) || isEmptyObject(fulfillmentOptions.buyOnlineShipToStore),
      error: 'Neither BOPIS nor BOSS are objects (or are missing) in product.fulfillmentOptions',
      data: { product }
    });
  }
  return fulfillmentOptions.buyOnlinePickupInStore !== undefined;
}


export const hardwareAssembliesSelectorByActiveEdge = (state) => {
  let hardwareAssemblies = [];
  if (state.hardwareSubApp && state.hardwareSubApp.products) {
    const { skuChoices } = state.hardwareSubApp;
    assert({ condition: skuChoices, error: 'Empty Sku Choices for SubApp!', data: { skuChoices } });

    const { products } = state.hardwareSubApp;
    const productIds = Object.keys(products);
    hardwareAssemblies = productIds.map(productId => {
      const product = products[productId];
      const { name, shortLabel, partnerProductId } = product;

      const selectedHardware = selectedHardwarePriceSelector(productId)(state);

      const { price, quantityAvailable, omsId } = selectedHardware;

      return {
        productId,
        partnerProductId,
        name,
        shortLabel,
        edge: product.attributes.associatedChoicesInternalNames,
        price,
        quantityAvailable,
        omsId
      };
    });
    assert({ condition: Array.isArray(hardwareAssemblies), error: 'hardwareAssemblies is not an array', data: { hardwareAssemblies } });
  }
  const edge = getSelectedEdgeSelector(state);
  assert({ condition: typeof edge === 'string', error: `Wrong Edge Type`, data: { edge } });

  const results = hardwareAssemblies.filter(h => h.edge === edge);
  if (state.subApps && state.subApps.hardware) {
    const {hardware} = state.subApps;
    results.forEach(x => {
      x.quantityOrdered = null;
      const productId = +x.productId;
      const subAppProd = hardware[productId];
      x.isBOPIS = hardwareAssemblyIsBOPIS(subAppProd, productId);
      if (subAppProd && subAppProd.quantity) {
        x.quantityOrdered = subAppProd.quantity;
      }
    });
  }

  results.forEach(assertAssemblyShape);
  return results;
}


export const hardwareAssemblySelectorById = (state) => (id) => {
  const assemblies = hardwareAssembliesSelectorByActiveEdge(state);
  const assembly = assemblies.filter(a => a.productId === id);
  assert({ condition: assembly.length === 1, error: `Product Id ${id} must have exactly 1 assembly`, data: { assembly }});
  assertAssemblyShape(assembly[0]);
  return assembly[0];
}


export default hardwareAssembliesSelectorByActiveEdge;