import Api from '../../../services/axios';
import { assertHasShape } from '../../../apps/debug/assert';

export const loadSubAppData = (decksApiBaseUrl, assemblyId, storeId) => {
  const url = `${decksApiBaseUrl}/GetAssemblyProducts?assemblyid=${assemblyId}&storeId=${storeId}&siteId=11`;
  const api = Api();
  return api.get(url).then(response => {
      assertHasShape('Sub App', response.data, {
        'products': 'populatedObject',
        'options': 'populatedObject',
        'choices': 'populatedObject',
        'skuChoices': 'populatedObject',
        'choiceSkus': 'populatedObject'
      });
      return response;
  });
};

export default loadSubAppData;
