import React from 'react';
import PropTypes from 'prop-types';
import './ErrorMessage.scss';
import ErrorBox from '../../svgs/errorBox';


const ErrorMessage = (props) => (
  <div className="errorMessage">
    <ErrorBox />
    <span className="attribute_label">{props.message}</span>
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};
export default ErrorMessage;
