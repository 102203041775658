/* eslint-disable no-param-reassign */
import produce from "immer";
import { createReducer } from '../utils';
import {
    UPDATE_SUB_APP_HARDWARE_PRICING,
    UPDATE_SUB_APP_HARDWARE_QUANTITY,
    SUB_APP_HARDWARE_SELECTED,
    SUB_APP_SELECTED_EDGE_CHANGED,
} from '../actions';

const initProduct = (draft, subappId, productId) => {
    if(!draft[subappId][productId])
        draft[subappId][productId] = {productId};
};

const onSetPricing = (state, { subappId, productId, pricing }) => {
    return produce(state, (draft) => {
        initProduct(draft, subappId, productId);
        draft[subappId][productId].pricing = pricing;
    });
};

const onSetQuantity = (state, { subappId, productId, quantity }) => {
    return produce(state, (draft) => {
        initProduct(draft, subappId, productId);
        draft[subappId][productId].quantity = quantity;
    });
};

const onSetSelected = (state, { subappId, productId, selected }) => {
    return produce(state, (draft) => {
        initProduct(draft, subappId, productId);
        draft[subappId][productId].selected = selected;
    });
};

const onSelectedEdgeChange = (state) => {
    return produce(state, (draft) => {
        draft.hardware = {};
        draft.fascia = {};
    });
}

export const subApps = createReducer(
  {},
  {
    [UPDATE_SUB_APP_HARDWARE_PRICING]: onSetPricing,
    [UPDATE_SUB_APP_HARDWARE_QUANTITY]: onSetQuantity,
    [SUB_APP_HARDWARE_SELECTED]: onSetSelected,
    [ SUB_APP_SELECTED_EDGE_CHANGED]: onSelectedEdgeChange,
  },
);

export default subApps;
