import { put, takeLatest, select } from 'redux-saga/effects';

import { UPDATE_QUANTITY } from '../../apps/actions/actionTypes';
import { safeSaga } from '../utils';

import transform from '../../apps/estimator/dimensionTransformer';
import { getSelectedDimension } from '../../utils/getSelectedChoices';
import { getSelectedChoicesSelector } from '../../selectors';
import { estimateSqft, estimateCost } from '../../apps/estimator/calculators';

import setEstimatedSqft from '../../apps/actions/setEstimatedSqft';
import setEstimatedCost from '../../apps/actions/setEstimatedCost';
import {recalculatePrice} from '../../actions/index';
import { displayPriceSelector } from '../../selectors/catalog';

export function* quantityChanged(action) {
  const { newQuantity } = action.payload;

  const selectedChoices = yield select(getSelectedChoicesSelector);
  const dimensionChoice = getSelectedDimension(selectedChoices)[0];
  const dimension = transform(dimensionChoice.choiceValue);
  const estimatedSqft = estimateSqft(dimension, newQuantity);
  yield put(setEstimatedSqft(estimatedSqft));

  const price = yield select(displayPriceSelector);
  if (price) {
    const estimatedCost = estimateCost(price, newQuantity);
    yield put(setEstimatedCost(estimatedCost));
    yield put(recalculatePrice());
  }
}

export function* quantitySaga() {
  // console.log('Estimator Quantity Saga Started.');
  yield takeLatest(UPDATE_QUANTITY, safeSaga(quantityChanged));
}

export default quantitySaga;
