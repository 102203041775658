import PropTypes from 'prop-types';

import { OptionalUrl } from './url';


const ScrewColor = PropTypes.shape({
  id: PropTypes.number,
  internalName: PropTypes.string,
  isDefault: PropTypes.bool,
  longLabel: PropTypes.string,
  shortLabel: PropTypes.string,
  sequenceNumber: PropTypes.number,
  mediaUrl: OptionalUrl,
  tags: PropTypes.arrayOf(PropTypes.string)
});

export { ScrewColor };