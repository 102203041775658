// import { activateSpinnerATC } from './ATCSpinnerActions';
import { quantitySelector, hiddenOptionByOptionTypeName } from '../selectors';
import getEditedItemId from '../utils/getEditedItemId';
import { getCartableHardwareSelector } from '../selectors/hardware/getCartableHardwareSelector';
import { Utils } from '../common/utils';
import getThdStore from '../utils/getThdStore';
import kitCartOrchestrator from '../utils/Carting/kitCartOrchestration/kitCartOrchestrator';
import { catalogSkuSelector } from '../selectors/catalog/catalogSkuSelector';
import { getSelectedChoicesSelector } from '../selectors/getSelectedChoicesSelector';
import { activateSpinnerATC } from './ATCSpinnerActions';
import { getSelectedBoardPriceSelector } from '../selectors/getSelectedBoardPriceSelector';
import { assertAssemblyShape, assertHasShape } from '../apps/debug/assert';
import selectedHardwarePriceSelector from '../_SubApps/HardwareAssembly/selectors/selectedHardwarePriceSelector';
import configurationSettings from '../../decking-configurator/selectors/configuratorSettings'

const soAddtoCart = (cartOptions) => (dispatch, getState) => {
  dispatch(activateSpinnerATC(true));
  const state = getState();

  const hiddenChoices = {
    storeIdChoice: hiddenOptionByOptionTypeName('Decking_ExternalStoreId')(state),
    externalProductIdChoice: hiddenOptionByOptionTypeName('Decking_ExternalProductId')(state),
    externalVendorIdChoice: hiddenOptionByOptionTypeName('External_VendorId')(state),
    externalPartNumber: hiddenOptionByOptionTypeName('External_VendorPartNumber')(state),
    deckingSourceChoice: hiddenOptionByOptionTypeName('Configuration_Origin')(state),
  };

  const configuratorSettings = configurationSettings(state);
  const orchestratorContext = {
    apis: {
      omsApiUrl: configuratorSettings.omsApiUrl,
      thdCartApiUrl: configuratorSettings.thdCartApiUrl,
      externalCartUrl: configuratorSettings.externalCartUrl,
      configApiUrl: configuratorSettings.configApiUrl,
      customBaseUrl: configuratorSettings.customBaseUrl,
      decksApiUrl: configuratorSettings.decksApiUrl,
    },
    mode: configuratorSettings.configuratorMode,
    boardLineItemId: getEditedItemId(),
    siteId: configuratorSettings.siteId,
    hiddenChoices,
  };

  const cartableHardware = getCartableHardwareSelector(state);

  let hardwareItemsInfo = {};
  if (cartableHardware.length > 0) {
    hardwareItemsInfo = cartableHardware.reduce((dict, hw) => {
      const selectedHardware = selectedHardwarePriceSelector(hw.productId)(state);

      assertAssemblyShape(hw);
      assertHasShape('selectedHardware', selectedHardware, {
        price: 'number',
        omsId: 'string',
        isStock: 'boolean',
        quantityAvailable: 'number'
      });

      // eslint-disable-next-line no-param-reassign
      dict[hw.productId] = {
        productId: parseInt(hw.productId, 10),
        quantity: hw.quantityOrdered,
        unitPrice: selectedHardware.price,
        sku: selectedHardware.id,// id  is sku/THDOmsID or QCConfigurationId from Skuchoice
        isStock: selectedHardware.isStock,
        quantityAvailable: selectedHardware.quantityAvailable,
        choices: selectedHardware.selectedChoices.map((choice) => ({choiceValue: choice.choiceId, optionId: choice.optionId, choiceName: choice.choiceValue})),
        skuType: selectedHardware.skuType,
        isBOPIS: hw.isBOPIS,
      };
      return dict;
    }, {});
  }

  // console.log({ hardwareItemsInfo });
  const sku = catalogSkuSelector(state);
  const selectedchoices = [];
  const choices = getSelectedChoicesSelector(state);
  choices.forEach((choice) => {
    const selection = { choiceName: choice.choiceValue, optionId: choice.optionId, choiceValue: choice.choiceId };
    selectedchoices.push(selection);
  });

  const kitBoard = {
    productId: configuratorSettings.productId,
    quantity: quantitySelector(state),
    choices: selectedchoices,
    sku,
    unitPrice: getSelectedBoardPriceSelector(state),
  };

  const kit = {
    thdStoreId: Utils.getStoreIdFromStateOrCookie(state, getThdStore),
    board: kitBoard,
    hardware: hardwareItemsInfo,
  };

  kitCartOrchestrator(orchestratorContext, state, cartOptions)(kit);
};

export default soAddtoCart;
