import { createSelector } from 'reselect';
// import { hardwareEstimatesSelector } from './hardwareEstimatesSelector';
// import { hardwareIdsSelector } from './hardwareIdsSelector';

export const allHardwareIsAvailableSelector = createSelector(
/*   (state) => hardwareEstimatesSelector(state),
  (state) => hardwareIdsSelector(state),
  (estimates, ids) => {
    const eachHardwareHasAnEstimate = Object.keys(estimates).length === ids.length;
    if (!eachHardwareHasAnEstimate) {
      return false;
    }
    return (
      ids.map((i) => estimates[i].unitPrice || 0).filter((x) => x <= 0).length === 0
    );
  }, */

  // This needs to be redone to account for hardware data from multiple sub apps

  (state) => state.subApps,
  (subApps) => {
      let defaultChecked = false;
      if(subApps)
      {
        const rootObjectKeys = Object.keys(subApps);
            rootObjectKeys.forEach(key => {
                const objectKey = Object.values(subApps[key]);
                const priceMatch = objectKey.filter(m => m.selected);
                priceMatch.forEach(() => {
                    defaultChecked = true;
                });
            });
      }
      return defaultChecked;
  },
);
export default allHardwareIsAvailableSelector;
