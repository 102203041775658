
import { createSelector } from 'reselect';



export const hardwareProductsSelector = createSelector(

  
  (state) => state.hardware,

  (hardware) => (hardware ? hardware.hardwareProducts || {} : {}),
);

export default hardwareProductsSelector;
