import { createSelector } from 'reselect';
import values from 'lodash/values';
import productsSelector from './productsSelector';
import selectionsSelector from './selectionsSelector';
import choiceSkusSelector from './choiceSkusSelector';
import skuChoicesSelector from './skuChoicesSelector';

export const selectedHardwarePriceSelector = (productId) => createSelector(
  productsSelector,
  selectionsSelector,
  choiceSkusSelector,
  skuChoicesSelector,
  (products, selections, choices, skuChoice) => {
    const defaultPrice = {omsId: "", price: 0.00, quantityAvailable: 0, selectedChoices:[] };
    if (products && products[productId] && selections && choices && skuChoice) {
      const selection = selections[productId];
      let omsId;
      if (selection) {
          const choice =  selection[Object.keys(selection)[0]].choiceId;
          if(!choice || !choices[choice]) {
              return defaultPrice;
            }

        if (choices[choice] && choices[choice].length > 0) {
            // eslint-disable-next-line prefer-destructuring
            omsId = choices[choice][0];
            if(skuChoice[omsId])
            {
              return {...skuChoice[omsId], omsId, selectedChoices: values(selection)};
            }
        }
        return defaultPrice;
      }
        // look into the product for the omsId

        const product = products[productId];
        omsId = product.partnerProductId;
        let selectedSku = skuChoice && skuChoice[omsId];
        //hack to determine QC skuChoice for hardware without selection
        if(products[productId].attributes && products[productId].attributes.associatedChoicesInternalNames && products[productId].attributes.associatedChoicesInternalNames === 'Grooved') {
            const productSkuChoice = values(skuChoice).find((sku) => sku.productId === parseInt(productId, 10));
            if(productSkuChoice && productSkuChoice.skuType === 'QuoteCenter') {
              selectedSku = productSkuChoice;
            }
        }

        if(selectedSku) {
          return {...selectedSku, omsId, selectedChoices:[]};
        }
        return defaultPrice;
    }
    return defaultPrice;
  },

);


export default selectedHardwarePriceSelector;
