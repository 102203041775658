/* istanbul ignore file */
import { combineReducers } from 'redux';
import { configuratorReducer } from '@gcc/configurator';
import { reducer as hardwareReducer } from '../_SubApps/HardwareAssembly/index'; 
import { apiErrors } from './apiErrors';
import { sagaErrors } from './sagaErrors';
import { imageGallery } from './imageGallery';
import { hardware } from './hardware';
import { spinnerATC } from './spinnerATC';
import checkoutReducer from './checkoutReducer';
import estimationReducer from './estimationReducer';
import productDelivery from './productDeliveryReducer';
import catalog from './catalogReducer';
import assembly from './assemblyReducer';
import subApps from './subApps';
import config from './configSettingsReducer';

export const reducer = combineReducers({
  apiErrors,
  sagaErrors,
  imageGallery,
  hardware,
  spinnerATC,
  configurator: configuratorReducer,
  checkout: checkoutReducer,
  estimation: estimationReducer,
  productDelivery,
  catalog,
    hardwareSubApp: hardwareReducer,
  assembly,
  subApps,
  config,
});

export default { reducer };
