

import { filterSelections } from '@gcc/configurator';
import filter from 'lodash/filter';

const CHOICES = {
  Edge: 'Edge',
  EdgeScalloped: 'EdgeScalloped',
  PrimaryColor: 'Primary Color',
  DimensionxLength: 'DimensionxLength',
};


export const getSelectedChoices = (options, choices) => {
  const selections = filter(choices, (choice) => filterSelections(choice, options, choices))
    .map((choice) => {
      const option = options[choice.optionId];

      return {
        choiceId: choice.id,
        optionId: choice.optionId,
        choiceValue: choice.value,
        optionType: option.isDependent ? '' : option.optionType,
        internalName: choice.internalName,
      };
    });

  return filter(selections, (selection) => selection.optionType === CHOICES.Edge
    || selection.optionType === CHOICES.EdgeScalloped
    || selection.optionType === CHOICES.PrimaryColor
    || selection.optionType === CHOICES.DimensionxLength);
};

export const getSelectableChoices = (choices) => choices.filter((c) => (c.optionType === CHOICES.Edge)
    || (c.optionType === CHOICES.EdgeScalloped)
    || (c.optionType === CHOICES.PrimaryColor)
    || (c.optionType === CHOICES.DimensionxLength));

export const getSelectedDimension = (selectedChoices) => selectedChoices.filter((selection) => (selection.optionType === CHOICES.DimensionxLength));

export const getSelectedEdge = (selectedChoices) => selectedChoices.filter((selection) => (selection.optionType === CHOICES.Edge || selection.optionType === CHOICES.EdgeScalloped));

export const getSelectedPrimaryColor = (selectedChoices) => selectedChoices.filter((selection) => (selection.optionType === CHOICES.PrimaryColor));

export const getSelectedEdgeFromOptionChoices = (options, choices) => {
  const selectedChoices = getSelectedChoices(options, choices);
  if (selectedChoices.length > 0) {
    const selectedEdge = getSelectedEdge(selectedChoices);
    return selectedEdge.length > 0 ? selectedEdge[0] : undefined;
  }
  return undefined;
};

export default {
  getSelectedChoices,
  getSelectableChoices,
  getSelectedDimension,
  getSelectedEdge,
  getSelectedPrimaryColor,
  getSelectedEdgeFromOptionChoices,
};
