import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const HardwareAssembly = styled.div`
  margin: 2em 0;
  ${props =>
    props.disable &&
    `
      background: #eee;
    `}
`;
