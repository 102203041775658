import { put, takeLatest, select } from 'redux-saga/effects';
import { CHOICE_CHANGED_SUCCESS } from '../../actions/configuratorActions';
import { safeSagaFilter, safeSaga } from '../utils';

import transform, { IsValidDimension } from '../../apps/estimator/dimensionTransformer';
import { quantitySelector } from '../../selectors';
import { estimateSqft, estimateCost } from '../../apps/estimator/calculators';

import setEstimatedSqft from '../../apps/actions/setEstimatedSqft';
import setEstimatedCost from '../../apps/actions/setEstimatedCost';
import {recalculatePrice} from '../../actions/index';
import { displayPriceSelector } from '../../selectors/catalog';

export function* boardSizeChange(action) {
  const { choiceValue: newDimension } = action;
  const dimension = transform(newDimension);
  const quantity = yield select(quantitySelector);
  const estimatedSqft = estimateSqft(dimension, quantity);
  yield put(setEstimatedSqft(estimatedSqft));

  const price = yield select(displayPriceSelector);
  const estimatedCost = estimateCost(price, quantity);
  yield put(setEstimatedCost(estimatedCost));
  yield put(recalculatePrice());
}

const DimensionChoiceHasChanged = (action) => action.type === CHOICE_CHANGED_SUCCESS
  && action.choiceValue
  && IsValidDimension(action.choiceValue);

export function* boardSizeSaga() {
  // console.log('Estimator BoardSize Saga Started.');
  yield takeLatest(
    safeSagaFilter(DimensionChoiceHasChanged),
    safeSaga(boardSizeChange),
  );
}

export default boardSizeSaga;
