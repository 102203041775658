import memoize from 'lodash/memoize';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import choiceSkusSelector from '../../selectors/catalog/choiceSkusSelector';
import { getSelectedPrimaryColor } from '../../utils/getSelectedChoices';
import { getSelectedChoicesSelector } from '../../selectors';



const dimensionIsFulfillable = (state, choiceSkus, boardChoiceId) => {

  const selectedChoices = getSelectedChoicesSelector(state);
  const colorChoices = getSelectedPrimaryColor(selectedChoices);
  const colorChoice = colorChoices[0];
  const colorChoiceId = colorChoice.choiceId;
  const boardColorIntersection = intersection(choiceSkus[boardChoiceId], choiceSkus[colorChoiceId]);
  const boardSupportsColor = boardColorIntersection.length > 0;
  
  return !isEmpty(choiceSkus[boardChoiceId]) && boardSupportsColor;
}

const dimensionAvailabilityEvaluatorFactory = (state) => (dimensionChoiceId) => {
  const choiceSkus = choiceSkusSelector(state);
  return dimensionIsFulfillable(state, choiceSkus, dimensionChoiceId);
};

export default memoize(dimensionAvailabilityEvaluatorFactory);
