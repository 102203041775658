import Api from './axios';

 export const getproductInfoApi = (eleanorBaseUrl,partnerProductId,siteId) => {
  const url = `${eleanorBaseUrl}/api/ProductDetails/PartnerProductId/${partnerProductId}/site/${siteId}`;
  const api = Api();
  Object.assign(api.defaults, { crossDomain: true });
  return api.get(url);
};

export default getproductInfoApi;
