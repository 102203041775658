import { takeEvery } from 'redux-saga/effects';
import { safeSaga } from '../utils';
import { REDIRECT_TO_THD } from '../../actions/redirectToThd';

function* redirectToThd(action) {
  console.assert(action && action.externalCartUrl, `Bad action value: ${JSON.stringify(action)}`)

  // Workaround for a bug...
  const url = action.externalCartUrl.toString()

  yield setTimeout(() => { window.location = url; }, 3000)
}

export function* redirectToThdSaga() {
  // console.log('redirectToThdSaga')
  yield takeEvery(REDIRECT_TO_THD, safeSaga(redirectToThd));
}

export default redirectToThdSaga;
