import memoize from 'lodash/memoize';
import productsWithColorOptionsProjector from './productsWithColorOption';
import selectionsSelector from '../selectors/selectionsSelector';
import choicesSelector from '../selectors/choicesSelector';


export const selectedColorProjector = (productId) => memoize((state) => {
  const productsWithColorOptions = productsWithColorOptionsProjector(state);
  const colorOptionData = productsWithColorOptions.find((p) => p.productId === productId);
  if (!colorOptionData) return {};

  const selections = selectionsSelector(state);
  const theProductsSelections = selections[productId];
  if (!theProductsSelections) return {};

  const theColorSelection = theProductsSelections[colorOptionData.colorOptionId];
  if (!theColorSelection) return {};

  const choices = choicesSelector(state);
  const selectedChoice = choices[theColorSelection.choiceId];
  return selectedChoice;
});

export default memoize(selectedColorProjector);
