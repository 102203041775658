import styled from 'styled-components';

const imageHeight = 30;
const verticalPadding = 5;

// eslint-disable-next-line import/prefer-default-export
export const BuildDropdownProduct = styled.div`
  margin: 30px 0 30px 30px;
`;

export const DropdownTitle = styled.h5`
  font-size: 14px;
  margin-bottom: 5px;
`;

export const DropDownLabel = styled.button`
  positon:relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border-radius: 0;
  border: 1px solid #CCC;
  border-bottom: 3px solid #CCC;
  padding: 10px 40px 10px 10px;
  text-decoration: none;
  position: relative;
  font-size: 13px;
  line-height: 16px;
  transition: all 0.25s ease;

  ${props => !props.active && `
    background-color: #eee;
    cursor: default;
  `
  }
  
  &:before {
    content: "";
    height: 75%;
    border-left: 1px solid #ccc;
    top: 5px;
    position: absolute;
    right: 34px;
    color: #666;
  }

  &:after {
    content: "";
    background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48px' height='48px'%3E%3Cpath fill='%23f96302' d='M14.83,16.42L24,25.59l9.17-9.17L36,19.25l-12,12l-12-12L14.83,16.42z'/%3E%3C/svg%3E") no-repeat;
    top: 50%;
    right: 8px;
    background-size: 20px;
    background-position: 0;
    height: 20px;
    width: 0;
    padding: 0 0 0 20px;
    position: absolute;
    pointer-events: none;
    transition: all .25s ease;
    margin-top: -10px;
  }

`;

export const DropDownLabelImage = styled.img`
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
`;

export const DropDownOptions = styled.ul`
  display: none;
  position: absolute;
  list-style: none;
  max-height: ${4 * (imageHeight + verticalPadding * 2)}px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  &.open {
    display:block;
    bottom: 0;
    left: -1px;
    transform: translateY(100%);
    z-index: 12;
    background: #fff;
    border: 1px solid #CCC;
    border-top: 0;
    width: 100%;
    box-shadow: 0 0px 5px rgba(51,51,51,0.2);
  }
`;

export const DropDownOption = styled.span`
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: ${verticalPadding}px 10px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const DropDownOptionsItem = styled.li`

`;
