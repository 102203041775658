const localizer = typeof window !== 'undefined' ? window?.THD_LOCALIZER_AUTO_INIT?.Localizer : null;

const getThdStore = () => {
  let thdStore;
  if (localizer) {
    const toReduxFormat = ({ name, storeId, ...storeInfo }) => ({
      ...storeInfo,
      // match legacy names (used throughout app)
      storeName: name,
      storeNumber: storeId,
    });
  
    function updateGeolocationStoreInfo(localizer) {
      const storeInfo = localizer?.getLocalizedStore();
  
      if (storeInfo) {  
        thdStore = toReduxFormat(storeInfo);
      }
    }
    updateGeolocationStoreInfo(localizer);
    localizer?.on(localizer?.event?.LOCALIZED, updateGeolocationStoreInfo(localizer));
    localizer?.on(localizer?.event?.LOCALIZED_STORE_CHANGED, updateGeolocationStoreInfo(localizer));
  }
  return thdStore ? thdStore : {
      storeNumber: 121,
      storeName: 'Cumberland #121',
      zipcode: '30339',
  };
}

export default getThdStore;
