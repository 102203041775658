/**
 * Remove any `prerender-status-code` meta tags from the page.
 *
 * @param {int|boolean} value HTTP status code to send to Prerender.io.
 *        May be a number, `true` for `200`, or `false` for `503`.
 */
export default (value = true) => {
  let code;
  if (typeof value === 'boolean') {
    code = value ? 200 : 503;
  } else if (typeof value === 'number') {
    code = Math.trunc(value);
  } else {
    throw new Error('number or boolean expected');
  }

  Array.prototype.forEach.call(
    document.querySelectorAll('meta[name=prerender-status-code]'),
    node => node.setAttribute('content', code),
  );
};
