import React, { useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import * as S from './Styled.BuildDropdownProduct';

const BuildDropdownProduct = ({
  selectedColor, colorOptionsList, title, callback, active
}) => {
  const [dropDownIsOpen, setdropDownIsOpen] = useState(false);
  const [currentlySelecting, setCurrentlySelecting] = useState(false);

  const openDropdown = () => {
    setdropDownIsOpen(!dropDownIsOpen);
  };
  const checkDropDownStatus = () => {
    if (dropDownIsOpen && !currentlySelecting) setdropDownIsOpen(!dropDownIsOpen);
  };
  const onSelectOption = (e) => {
    const id = Number(e.target.getAttribute('data-colorid'));
    const newSelectedProduct = colorOptionsList.find((option) => id === option.id);
    callback(newSelectedProduct);
    setCurrentlySelecting(false);
  };
  const interceptClickOption = () => {
    setCurrentlySelecting(true);
  };
  const { id, shortLabel, mediaUrl } = selectedColor || colorOptionsList;

  return (
    <S.BuildDropdownProduct>
      <S.DropdownTitle>
        {title}
      </S.DropdownTitle>
      <S.DropDownLabel data-id={id} onClick={openDropdown} onBlur={checkDropDownStatus} active={active}>
        <S.DropDownLabelImage src={mediaUrl} alt={shortLabel} />
        {' '}
        <span>{shortLabel}</span>
        {active && (
          <S.DropDownOptions className={dropDownIsOpen ? 'open' : null}>
            {colorOptionsList.map((colorOption,i) => (
              <S.DropDownOptionsItem key={i}>
                <S.DropDownOption type="button" onMouseDown={interceptClickOption} onClick={onSelectOption} data-colorid={colorOption.id}>
                  <S.DropDownLabelImage src={colorOption.mediaUrl} alt={colorOption.shortLabel} />
                  {colorOption.shortLabel}
                </S.DropDownOption>
              </S.DropDownOptionsItem>
            ))}
          </S.DropDownOptions>
        )}
      </S.DropDownLabel>

    </S.BuildDropdownProduct>
  );
};

BuildDropdownProduct.defaultProps = {
  selectedColor: {},
  callback: noop,
  active: true,
};

BuildDropdownProduct.propTypes = {
  selectedColor: PropTypes.shape({}),
  colorOptionsList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  callback: PropTypes.func,
  active: PropTypes.bool,
};

export default BuildDropdownProduct;
